import { NavLink } from "react-router-dom";
import { AiOutlineMail, AiOutlineWhatsApp, AiOutlineInstagram } from 'react-icons/ai'
import './Footer.css'

const Footer: React.FC = () => {
  const ForceReload  = (event: React.MouseEvent<HTMLElement>, url: string) => {
      window.location.hash = url; 
      window.location.reload();
  };
  return (
  <>
    <div className="footer-container" style={{ backgroundImage: "linear-gradient(89.47deg, rgba(0, 0, 0, 0.5) 0.47%, rgba(0, 0, 0, 0) 100.37%), url(/images/footer.jpeg)" }}>
        <div className="footer-container-section footer-container-left">
            <div className="footer-container-left-logo">
                <img src="/images/logo-3.png" alt="" />
            </div>
            <div className="footer-container-left-quotes">
                
            </div>
            <div className="footer-container-left-socials">
                <div className="footer-socials-box">
                    <a href={`https://wa.me/6281351760773`}><AiOutlineWhatsApp/></a>
                </div>
                <div className="footer-socials-box">
                    <a href={`mailto:kevin@dinamikakargo.com`}><AiOutlineMail/></a>
                </div>
                <div className="footer-socials-box">
                    <a href={`https://www.instagram.com/dinamika_kargo/`}><AiOutlineInstagram/></a>
                </div>
            </div>
        </div>

        <div className="footer-container-section footer-container-mid">
            <div className="footer-container-mid-left">
                <div className="footer-mid-company">
                    <div className="footer-mid-title">
                        Company
                    </div>
                    <div className="footer-mid-company-content">
                        <div className="footer-mid-nav-content">
                            <NavLink to={`/`} className='footer-mid-company-nav-text'>Home</NavLink>
                        </div>
                        <div className="footer-mid-nav-content">
                            <NavLink to={`/about-us`} className='footer-mid-company-nav-text'>About Us</NavLink>
                        </div>
                        <div className="footer-mid-nav-content">
                            <NavLink to={`/contact-us`} className='footer-mid-company-nav-text'>Contact Us</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-container-mid-right">
                <div className="footer-mid-services">
                    <div className="footer-mid-title">
                        Services
                    </div>
                    <div className="footer-mid-service-content">
                        <div className="footer-mid-service-left">
                            <div className="footer-mid-nav-content">
                                <a onClick={(e) => ForceReload(e, "#sea-fright")} href={`/our-services#sea-fright`} className='footer-mid-service-nav-text'>Sea Fright</a>
                            </div>
                            <div className="footer-mid-nav-content">
                                <a onClick={(e) => ForceReload(e, "#air-fright")} href={`/our-services#air-fright`} className='footer-mid-service-nav-text'>Air Fright</a>
                            </div>
                            <div className="footer-mid-nav-content">
                                <a onClick={(e) => ForceReload(e, "#cargo-consolidation")} href={`/our-services#cargo-consolidation`} className='footer-mid-service-nav-text'>Cargo Consolidation</a>
                            </div>
                            <div className="footer-mid-nav-content">
                                <a onClick={(e) => ForceReload(e, "#door-to-door-services")} href={`/our-services#door-to-door-services`} className='footer-mid-service-nav-text'>Door-To-Door Services</a>
                            </div>
                        </div>
                        <div className="footer-mid-service-right">
                            <div className="footer-mid-nav-content">
                                <a onClick={(e) => ForceReload(e, "#custom-brokerage-services")} href={`/our-services#custom-brokerage-services`} className='footer-mid-service-nav-text'>Custom Brokerage Services</a>
                            </div>
                            <div className="footer-mid-nav-content">
                                <a onClick={(e) => ForceReload(e, "#warehousing-and-distribution")} href={`/our-services#warehousing-and-distribution`} className='footer-mid-service-nav-text'>Warehousing & Distribution</a>
                            </div>
                            <div className="footer-mid-nav-content">
                                <a onClick={(e) => ForceReload(e, "#project-cargoes")} href={`/our-services#project-cargoes`} className='footer-mid-service-nav-text'>Project Cargoes</a>
                            </div>
                            <div className="footer-mid-nav-content">
                                <a onClick={(e) => ForceReload(e, "#packing-and-removal")} href={`/our-services#packing-and-removal`} className='footer-mid-service-nav-text'>Packing & Removal</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-container-section footer-container-bottom">
            © Copyrights 2023  PT Pacific Dinamika Cargo
        </div>
    </div>
  </>
  );
};

export default Footer;