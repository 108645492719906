import React from 'react';

interface IntroBoxInt{
    numMessage: string
    descMessage: string
}
const IntroBox: React.FC<IntroBoxInt> = (props) => {
    return (
        <div className='home-intro-box'>
            <div className='home-intro-box-num'>
                <span className='home-intro-message-emp'>{props.numMessage}</span>
            </div>
            <div className='home-intro-box-desc'>
                {props.descMessage}
            </div>
        </div>
    );
};

export default IntroBox;