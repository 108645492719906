import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FloatingWhatsapp from '../components/Whatsapp'
import './AboutUs.css'

const AboutUs: React.FC = () => {
  return (
    <>
      <FloatingWhatsapp/>
      <Navbar/>
        <div className="about-us-top-container" style={{ backgroundImage: "url(/images/about-us-header.jpg)" }}/>
        <div className="about-us-container">
          <div className="about-us-left-bar"></div>
          <div className="about-us-description">
            <h1>About Us</h1>
            <div className="about-us-description-text">
              PT. Pacific Dinamika Cargo (PDC) was incorporated in August 2000 with its core business in International Freight Forwarding. Ours specializes in all phases of Sea Freight, Air Freight, Consolidation, Door-to-Door, Project cargo, Warehousing, and Packing. <br/> <br/>
              The Company was founded by experienced and motivated people in the forwarding industry, and our team has been working in the international transportation business for more than 20 years. <br/> <br/>
              In line with our company policy towards globalization and the challenges ahead, we are now progressively embarking on meeting the rapid demand from our loyal customers, international freight forwarders, and overseas agents/partners for the much-needed value-added and cost-efficient services in this new era of global logistic solution. <br/> <br/>
              With our availability to complement the needs of overseas agents and partners. we are optimistic about our experienced staff, who are trained to adapt with cultural flexibility and are capable of creating an effective working environment, and it's our mindset that good performance starts with clear goals in achieving the ultimate objective
              In a few years of our existence, we have established our presence in the country by competing with the freight forwarding companies in Indonesia. We keep developing to adapt to a fluctuating market. <br/> <br/>
            </div>
          </div>

          <div className="about-us-vision-mission">

            <div className="about-us-vision">
              <h1>Our Vision</h1>
              <div className="about-us-vision-mission-content">
                <div className="about-us-vision-mission-img-container">
                  <img src="/images/vision.jpg" alt="" className='about-us-vision-img'/>
                </div>
                <div className="about-us-vision-mission-text-container">
                  <div className="about-us-vision-mission-text">
                    To add value to cargo delivery service and to be a dynamic freight-forwarding company that continuously strives to develop opportunities for profitable growth for our core business
                  </div>
                </div>
              </div>
            </div>

            <div className="about-us-mission">
              <h1>Our Mission</h1>
              <div className="about-us-vision-mission-content">
                <div className="about-us-vision-mission-text-container">
                  <div className="about-us-vision-mission-text">
                    We concentrate on providing better services that are comfortable, secure, and trusted.
                    We strive to develop a disciplined work environment to create perfect human resources and experts in the field.
                  </div>
                </div>
                <div className="about-us-vision-mission-img-container">
                  <img src="/images/mission.jpg" alt="" className='about-us-vision-img'/>
                </div>
              </div>
            </div>
            
          </div>

        </div>
      <Footer/>
    </>
  );
};

export default AboutUs;