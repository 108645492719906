import { useState } from 'react';
import { NavLink } from "react-router-dom";
import { BsFillCaretDownFill } from 'react-icons/bs'
import './Navbar.css'

interface redirectFunc {
  (event: React.MouseEvent<HTMLElement>, redirectNum: number): void;
}
interface ServiceNavbarInt{
  onClickFunc?: redirectFunc
}
const Navbar: React.FC<ServiceNavbarInt> = (props) => {
  const forceReload  = (event: React.MouseEvent<HTMLElement>, url: string) => {
      window.location.hash = url; 
      window.location.reload();
  };
  const toggleService = (event: React.MouseEvent<HTMLElement>, page: number) => {
    if(page === 1) {
      setState({main: "block", service: "none"});
    } else {
      setState({main: "none", service: "block"});
    }
  };

  const [state, setState] = useState({main: "block", service: "none"})

  const isActiveStyle = {
    opacity: '100%'
  };
  return (
  <>
    {/* Desktop */}
    <div className="navbar-container">
      <div className="navbar-content-logo">
        <img src='images/logo.png' alt='logo' className='navbar-logo'></img>
      </div>
      <div className="navbar-content">
        <NavLink style={({ isActive }) => isActive ? isActiveStyle : {}} to={`/`} className='navbar-content-text'>Home</NavLink>
      </div>
      <div className="navbar-content">
        <NavLink style={({ isActive }) => isActive ? isActiveStyle : {}} to={`/about-us`} className='navbar-content-text'>About Us</NavLink>
      </div>
      <div className="navbar-content navbar-content-our-services">
        <div className="navbar-dropdown">
          <NavLink style={({ isActive }) => isActive ? isActiveStyle : {}} to={`/our-services`} className='navbar-content-text'>Our Services <BsFillCaretDownFill className="navbar-dropdown-icon"/></NavLink>
          <div className="navbar-dropdown-content">
            <NavLink to={`/our-services#sea-fright`} onClick={(e) => props.onClickFunc && props.onClickFunc(e, 1)}><span>Sea Fright</span></NavLink>
            <NavLink to={`/our-services#air-fright`} onClick={(e) => props.onClickFunc && props.onClickFunc(e, 2)}><span>Air Fright</span></NavLink>
            <NavLink to={`/our-services#cargo-consolidation`} onClick={(e) => props.onClickFunc && props.onClickFunc(e, 3)}><span>Cargo Consolidation</span></NavLink>
            <NavLink to={`/our-services#door-to-door-services`} onClick={(e) => props.onClickFunc && props.onClickFunc(e, 4)}><span>Door to Door Services</span></NavLink>
            <NavLink to={`/our-services#custom-brokerage-services`} onClick={(e) => props.onClickFunc && props.onClickFunc(e, 5)}><span>Custom Brokerage Services</span></NavLink>
            <NavLink to={`/our-services#warehousing-and-distribution`} onClick={(e) => props.onClickFunc && props.onClickFunc(e, 6)}><span>Warehousing and Distribution</span></NavLink>
            <NavLink to={`/our-services#project-cargoes`} onClick={(e) => props.onClickFunc && props.onClickFunc(e, 7)}><span>Project Cargoes</span></NavLink>
            <NavLink to={`/our-services#packing-and-removal`} onClick={(e) => props.onClickFunc && props.onClickFunc(e, 8)}><span>Packing and Removal</span></NavLink>
          </div>
        </div>
      </div>
      <div className="navbar-content">
        <NavLink style={({ isActive }) => isActive ? isActiveStyle : {}} to={`/contact-us`} className='navbar-content-text'>Contact Us</NavLink>
      </div>
    </div>

    {/* Mobile */}
    <input type="checkbox" id="main-navigation-toggle" className="btn btn--close" title="Toggle main navigation"  onClick={(e) => toggleService(e, 1)}/>
    <div className="toggler-container"/>

    <label htmlFor="main-navigation-toggle">
      <span className="navigation-toggle-span"></span>
    </label>
    <nav id="main-navigation" className="nav-main">
      <ul className="menu" style={{display : state.main}}>
        <li className="menu__item">
          <a href={`/`} className='menu__link'>Home</a>
        </li>
        <li className="menu__item">
          <a href={`/about-us`} className='menu__link'>About Us</a>
        </li>
        <li className="menu__item">
          <span className='menu__link' onClick={(e) => toggleService(e, 0)}>Our Services</span>
        </li>
        <li className="menu__item">
          <a href={`/contact-us`} className='menu__link'>Contact Us</a>
        </li>
      </ul>

      <ul className="menu-service menu" style={{display : state.service}}>
        <li className="menu__item">
          <span onClick={(e) => forceReload(e, `#air-freight`)} className='menu__link'>Air Freight</span>
        </li>
        <li className="menu__item">
          <span onClick={(e) => forceReload(e, `#sea-freight`)} className='menu__link'>Sea Freight</span>
        </li>
        <li className="menu__item">
          <span onClick={(e) => forceReload(e, `#cargo-consolidation`)} className='menu__link'>Cargo Consolidation</span>
        </li>
        <li className="menu__item">
          <span onClick={(e) => forceReload(e, `#door-to-door-services`)} className='menu__link'>Door to Door Services</span>
        </li>
        <li className="menu__item">
          <span onClick={(e) => forceReload(e, `#custom-brokerage-services`)} className='menu__link'>Custom Brokerage Services</span>
        </li>
        <li className="menu__item">
          <span onClick={(e) => forceReload(e, `#warehousing-and-distribution`)} className='menu__link'>Warehousing and Distribution</span>
        </li>
        <li className="menu__item">
          <span onClick={(e) => forceReload(e, `#project-cargoes`)} className='menu__link'>Project Cargoes</span>
        </li>
        <li className="menu__item">
          <span onClick={(e) => forceReload(e, `#packing-and-removal`)} className='menu__link'>Packing and Removal</span>
        </li>
      </ul>
    </nav>
  </>
  );
};

export default Navbar;