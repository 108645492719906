import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FloatingWhatsapp from '../components/Whatsapp'
import ServicePanel from '../components/OurService/ServicePanel';
// import ServiceNavbar from '../components/OurService/ServiceNavbar';
import './OurServices.css'

const OurServices: React.FC = () => {
  const ChangeServices = (event: React.MouseEvent<HTMLElement>, redirectNum: number) => {
    event.preventDefault();
    setState({assignedNum: redirectNum})
  };

  const [state, setState] = useState({assignedNum: 1})

  useEffect(() => {
    const urlHashMap = new Map<string, number>([
        ["sea-fright", 1],
        ["air-fright", 2],
        ["cargo-consolidation", 3],
        ["door-to-door-services", 4],
        ["custom-brokerage-services", 5],
        ["warehousing-and-distribution", 6],
        ["project-cargoes", 7],
        ["packing-and-removal", 8]
    ]);

    var num = urlHashMap.get(window.location.hash.replace('#',''))
    if (num != null) {
      setState({assignedNum: num})
    }

    window.scrollTo(0, 0);

  }, []);

  
  return (
    <>
      <FloatingWhatsapp/>
      <Navbar onClickFunc={ChangeServices}/>
        <div className="our-services-container">
            <ServicePanel num={state.assignedNum}/>
        </div>
      <Footer/>
    </>
  );
};

export default OurServices;