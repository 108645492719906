import React from 'react';
import './Whatsapp.css'

const FloatingWhatsapp: React.FC = (props) => {
    return (
        <a href={`https://wa.me/6281351760773`}>
            <div className="floating-whatsapp" style={{ backgroundImage: "url(/images/whatsapp.png" }}/>
        </a>
    );
};

export default FloatingWhatsapp;