import React from 'react';
import { useNavigate } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import CustomButton from '../components/Button';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import './Carousel.css'

interface ServiceCarouselInt{
    num: number
    title: string
    description: string
    url: string
}
const ServiceCarouselContent: React.FC<ServiceCarouselInt> = (props) => {
    const navigate = useNavigate();
    const navigateToUrl = () => {
        navigate('/our-services#' + props.url);
    };

    return (
        <div className='carousel-image-container carousel-image-container-service' style={{ backgroundImage: "linear-gradient(360deg, rgba(0, 0, 0, 0.9) 25.04%, rgba(0, 0, 0, 0) 74%), url(/images/services-" + props.num + ".jpg" }}>
            <div className="carousel-image-content-container">
                <div className="carousel-image-content carousel-image-content-services">
                    <div className="carousel-image-content-services-text">
                        {props.title}
                    </div>
                    <div className="carousel-image-content-services-desc">
                        {props.description}
                    </div>

                    <div className="carousel-image-content-services-button">
                        <CustomButton type='button' description='Find Out More' onClickFunc={navigateToUrl}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
const ServiceCarousel: React.FC = () => {
    return (
        <Carousel showArrows={true} autoPlay={false} showStatus={false} swipeable={true}  emulateTouch={true} showThumbs={false} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={20} className='carousel-container-service'>
            <ServiceCarouselContent num={1} title='Sea Fright' url='sea-fright' description='Pacific Dinamika has built a good relationship with shipping carriers to provide you with reasonable ocean freight and a flexible shipping schedule that suit you.'/>
            <ServiceCarouselContent num={2} title='Air Fright' url='air-fright' description='Pacific Dinamika offer a short-time delivery service without being hampered by weather conditions. Our customer service is ready 24/7 to support your need for the best services.'/>
            <ServiceCarouselContent num={3} title='Cargo Consolidation' url='cargo-consolidation' description='Pacific Dinamika provide package delivery needs with the same commitment we give to our customers with Full Container Loaded (FCL) delivery needs.'/>
            <ServiceCarouselContent num={4} title='Door-To-Door Services' url='door-to-door-services' description="Door to door is one of Pacific Dinamika's services that main advantages are delivery without worry about permits and documentation."/>
            <ServiceCarouselContent num={5} title='Custom Brokerage Services' url='custom-brokerage-services' description="Pacific Dinamika has the resources and qualifications to offer a custom brokerage service for your custom problem."/>
            <ServiceCarouselContent num={6} title='Warehousing & Distribution' url='warehousing-and-distribution' description="Warehousing services for cargoes that are not required for immediate use could be stored in our warehouse at a reasonable charge."/>
            <ServiceCarouselContent num={7} title='Project Cargoes' url='project-cargoes' description="PT. Pacific Dinamika Cargo handles project-specific shipments, including over-size and over-dimensional cargo."/>
            <ServiceCarouselContent num={8} title='Packing & Removal' url='packing-and-removal' description="Our well-trained team of professional packers and removals is always on hand to move your personnel, household goods, and personal effects anytime the need arises. "/>
        </Carousel>
    );
};

export default ServiceCarousel;