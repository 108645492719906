import React from 'react';
import './Button.css'

interface buttonFunc {
    (event: React.MouseEvent<HTMLElement>): void;
}
interface CustomButtonInt{
    description: string
    customClass?: string
    form?: string
    type: "button" | "submit" | "reset" | undefined
    onClickFunc: buttonFunc
}
const CustomButton: React.FC<CustomButtonInt> = (props) => {
    return (
        <button onClick={props.onClickFunc} className={'custom-button ' + props.customClass} type={props.type} form={props.form}>{props.description}</button>
    );
};

export default CustomButton;