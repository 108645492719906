import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FloatingWhatsapp from '../components/Whatsapp'
import emailjs from '@emailjs/browser';
import './ContactUs.css'


const ContactUs: React.FC = () => {
  const navigate = useNavigate();
  const form = useRef<HTMLFormElement>(null);
  
  const SubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    var formCurrent : HTMLFormElement | string
    if (form.current !== null) {
      formCurrent = form.current
    } else {
      formCurrent = ""
    }
    emailjs.sendForm('service_2z6qbft', 'template_d1zs4et', formCurrent, 'TghPj2VZHOgQE94HV')
      .then((result) => {
        console.log(result)
        alert("Your message has been sent")
        navigate('/');
      }, (error) => {
        console.log(error)
        alert("Error sending message, please try again.")
      });
  };
  
  return (
    <>
      <FloatingWhatsapp/>
      <Navbar/>
          <div className="contact-us-container">
            {/* Title */}
            <div className="contact-us-title-container">
              <div className="contact-us-title-before"/>
              <div className="contact-us-title">
                <div className="contact-us-title-cell">
                  Contact Us
                </div>
              </div>
              <div className="contact-us-title-bar"/>
            </div>
            {/* End of Title */}

            {/* Maps */}
            <div className="contact-us-top-container">
              <div className="contact-us-top-left">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15867.84292851733!2d106.7962538!3d-6.1359785!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa9aef412aa754429!2sPT.%20Pacific%20Dinamika%20Cargo!5e0!3m2!1sid!2sid!4v1672393768857!5m2!1sid!2sid" className='contact-us-maps' loading="lazy" title="page-map"></iframe>
              </div>
              <div className="contact-us-top-right">
                <div className="contact-us-address">
                  <b>Office Location</b> <br/>
                  PT. Pacific Dinamika Cargo <br/>
                  Komp. Rukan Bandengan Indah <br/>
                  Jl. Bandengan Utara 80 Blok B26 <br/>
                  Jakarta 14440 - Indonesia <br/>
                  <br/>
                  <b>Contact Information</b> <br/>
                  Phone : (021) 6612118 <br/>
                  Email : kevin@dinamikakargo.com
                </div>
              </div>
            </div>
            {/* End of Maps */}

            {/* Form */}
            <div className="contact-us-form-container">
              <form ref={form} className='contact-us-form' onSubmit={SubmitForm}>
                <input type="text" placeholder='Name' name='name' className='contact-us-form-input' required={true}/>
                <input type="email" placeholder='Email Address' name='email_address' className='contact-us-form-input' required={true}/>
                <input type="text" placeholder='Company Name' name='company_name' className='contact-us-form-input' required={true}/>
                <textarea className='contact-us-form-input' name='message' placeholder='Message' required={true}/>
                
                <div className="contact-us-form-button">
                  <input type="submit" value={"Send"} className="contact-us-form-submit-button"/>
                </div>
              </form>
            </div>
            {/* End of Form */}
          </div>
      <Footer/>
    </>
  );
};

export default ContactUs;