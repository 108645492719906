import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './pages/Home';
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import OurServices from "./pages/OurServices";
import './App.css';

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home/>,
    },
    {
      path: "/contact-us",
      element: <ContactUs/>,
    },
    {
      path: "/about-us",
      element: <AboutUs/>,
    },
    {
      path: "/our-services",
      element: <OurServices/>,
    },
  ]);
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
