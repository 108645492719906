import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import CustomButton from '../components/Button';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import './Carousel.css'

const HomeCarousel: React.FC = () => {
  const carouselContent = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const navigateToAboutUs = () => {
        navigate('/about-us');
    };
    const navigateToContactUs = () => {
        navigate('/contact-us');
    };

    useEffect(() => {
      const { innerWidth: width } = window;
      if (width <= 600) {
        if (carouselContent.current != null) {
          carouselContent.current.style.backgroundImage = "linear-gradient(269.35deg, rgba(0, 0, 0, 0.9) 0.7%, rgba(0, 0, 0, 0) 150.27%), url(/images/home-carousel-2.jpg)";
        }
      }
    }, []);

    return (
        <Carousel showArrows={true} autoPlay={false} showStatus={false} swipeable={true} emulateTouch={true} showThumbs={false} preventMovementUntilSwipeScrollTolerance={true} swipeScrollTolerance={20}>
            <div className='carousel-image-container' style={{ backgroundImage: "linear-gradient(89.47deg, rgba(0, 0, 0, 0.9) 0.47%, rgba(0, 0, 0, 0) 70.37%), url(/images/home-carousel-1.jpg)" }}>
                <div className="carousel-image-content-container">
                    <div className="carousel-image-content">
                        <div className="carousel-image-content-intro">
                            <div className="carousel-image-content-intro-text">
                                PT Pacific Dinamika Cargo
                            </div>
                            <div className="carousel-image-content-intro-sub-text">
                                Your Logistic Solution
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='carousel-image-container' style={{ backgroundImage: "linear-gradient(269.35deg, rgba(0, 0, 0, 0.9) 0.7%, rgba(0, 0, 0, 0) 80.27%), url(/images/home-carousel-2.jpg)" }} ref={carouselContent}>
                <div className="carousel-image-content-container">
                    <div className="carousel-image-content carousel-image-content-right">
                        <div className="carousel-image-content-about-us-text">
                            About Us
                        </div>
                        <div className="carousel-image-content-about-us-sub-text">
                            PT. Pacific Dinamika Cargo (PDC) was incorporated in August 2000 with its core business in International Freight Forwarding. <br/><br/>
                            Ours specializes in all phases of Sea Freight, Air Freight, Consolidation, Door-to-Door, Project cargo, Warehousing, and Packing.

                            <div className="carousel-image-content-about-us-button">
                                <CustomButton type='button' description='Find Out More' onClickFunc={navigateToAboutUs}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='carousel-image-container' style={{ backgroundImage: "linear-gradient(89.47deg, rgba(0, 0, 0, 0.75) 0.47%, rgba(0, 0, 0, 0) 79.37%), url(/images/home-carousel-3.jpg)" }}>
                <div className="carousel-image-content-container">
                    <div className="carousel-image-content carousel-image-content-left">
                        <div className="carousel-image-content-contact-us-text">
                            Contact Us
                        </div>
                        <div className="carousel-image-content-contact-us-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15867.84292851733!2d106.7962538!3d-6.1359785!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa9aef412aa754429!2sPT.%20Pacific%20Dinamika%20Cargo!5e0!3m2!1sid!2sid!4v1672393768857!5m2!1sid!2sid" className='carousel-image-content-contact-us-map-iframe' title="carousel-map" loading="lazy"></iframe>
                        </div>

                        <div className="carousel-image-content-contact-us-button">
                            <CustomButton type='button' description='Find Out More' onClickFunc={navigateToContactUs}/>
                        </div>
                    </div>
                </div>
            </div>
        </Carousel>
    );
};

export default HomeCarousel;