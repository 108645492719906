import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FloatingWhatsapp from '../components/Whatsapp'
import HomeCarousel from '../components/HomeCarousel';
import ServiceCarousel from '../components/ServiceCarousel';
import IntroBox from '../components/Home/IntroBox'
import ValueCircle from '../components/Home/ValueCircle';
import './Home.css'

const Home: React.FC = () => {
  return (
    <>
      <FloatingWhatsapp/>
      <Navbar/>
      <div className='home-container'>
        <HomeCarousel/>
        
        {/* Intro Message */}
        <div className='home-intro-message-container'>
            <div className='home-intro-message-top'>
                <span className='home-intro-message-emp'>PT Pacific Dinamika Cargo</span> was founded by experienced and motivated people in the forwarding industry, and our team has been working in the <span className='home-intro-message-emp'>international transportation business</span> for more than 20 years.
            </div>
            <div className='home-intro-message-bottom'>
                <IntroBox numMessage='20.000+' descMessage='Loyal Customer'/>
                <IntroBox numMessage='150.000+' descMessage='Total of Delivery'/>
                <IntroBox numMessage='90%' descMessage='Customer Satisfaction'/>
                <IntroBox numMessage='50.000+' descMessage='Total Shipment'/>
                <IntroBox numMessage='10.000+' descMessage='Worldwide Shipping'/>
            </div>
        </div>
        {/* End of Intro Message */}

        {/* Home Services */}
        <div className="home-services-container-gradient"/>
        <div className="home-services-container">
          <div className="home-services-box-container">
            <div className="home-services-box-title">
              Our Services
            </div>
            <div className="home-services-box-desc">
              Our services have been developed based on a philosophy of identifying <b>client needs</b>, paying <b>attention to details</b>, and exceptionally providing <b>high service standards</b> at a highly competitive rate.
            </div>
          </div>
          <ServiceCarousel/>
        </div>
        <div className="home-services-container-gradient home-services-container-gradient-bottom"/>
        {/* End of Home Services */}

        {/* Corporate Value */}
        <div className="home-corporate-value-container">
          <div className="home-corporate-value-title">
            Our Corporate Value
            <hr className='home-corporate-value-title-line'/><hr className='home-corporate-value-title-line'/>
          </div>
          <div className="home-corporate-value-circle-container">
            <ValueCircle image='pro.jpg' description='PT Dinamika Kargo strive to embody professionalism in all aspects of their operations to build trust with their customers and maintain a positive reputation in the industry.' title='Professionalism'/>
            <ValueCircle image='trust.jpg' description='We strive to maintain a culture of integrity by promoting open and honest communication, adhering to ethical business practices, and holding ourselves accountable for their actions.' title='Integrity'/>
            <ValueCircle image='teamwork.jpg' description='Our companies promote teamwork by fostering a positive work environment, encouraging open communication, and providing opportunities for employees to work together.' title='Team Work'/>
          </div>
        </div>
        {/* End of Corporate Value */}

        {/* Our Clients */}
        <div className="home-our-clients-world-map-container">
          <div className="home-services-container-gradient"/>
          <div className="home-our-clients-world-map-content">
            <div className="home-our-clients-world-map-content-title">
              Distribution Network
            </div>
            <img src="/images/world-map.jpg" alt="" className='home-our-clients-world-map-img'/>
          </div>
          <div className="home-services-container-gradient home-services-container-gradient-bottom"/>
        </div>
      </div>
      
      <Footer/>
    </>
  );
};

export default Home;