import React from 'react';

interface ServicePanelInt{
    num: number
}
const ServicePanel: React.FC<ServicePanelInt> = (props) => {
    var title = ""
    var desc = <p/>

    if (props.num === 1) {
        title = "Sea Fright"
        desc = <p>
            We believe that as economies grow, shipping internationally becomes more popular. <br/><br/>

            PT. Pacific Dinamika Cargo provides delivery with large quantities, expansive space, and flexibility receipt cargo. <br/><br/>

            Through our journey for the past 20 years in a shipping company, we can provide the best service solution for you. <br/><br/>

            Pacific Dinamika also has built a good relationship with shipping carriers to provide you with reasonable ocean freight and a flexible shipping schedule that suit you.
        </p>
    } else if (props.num === 2) {
        title = "Air Fright"
        desc = <p>
            PT. Pacific Dinamika Cargo has provided air freight since 2000. <br/><br/>

            We offer a short-time delivery service without being hampered by weather conditions. <br/><br/>

            Pacific Dinamika is trusted with national and international airlines to offer the best choice that suits you. Our customer service is ready 24/7 to support your need for the best services.

        </p>
    } else if (props.num === 3) {
        title = "Cargo Consolidation"
        desc = <p>
            PT. Pacific Dinamika Cargo realizes that some of its clients' needs may vary. In the case of partial delivery with less volume than a full container, we provide those package delivery needs with the same commitment we give to our customers with Full Container Loaded (FCL) delivery needs. <br/><br/>

            Our operations cut across the airports and the seaports. Highly seasoned professionals are stationed at each port to oversee the lodging and the processing of the customs entries and the actual collection of the cargo and eventual delivery to destinations. <br/><br/>

            These processes are undertaken with significant consideration to meet your delivery target within the shortest possible time and at a very reasonable cost.
        </p>
    } else if (props.num === 4) {
        title = "Door-To-Door Services"
        desc = <p>
            Door to door is one of Pacific Dinamika's services that main advantages are delivery without worry about permits and documentation. <br/><br/>

            We also take good care and keep tracking your goods until they are delivered to your door.

        </p>
    } else if (props.num === 5) {
        title = "Custom Brokerage Services"
        desc = <p>
            PT. Pacific Dinamika Cargo believes doing business in export and import is very challenging; there are some documents and electronic forms that we must be fulfilled. And also, we must pay some taxes and duties depending on the commodity that is shipped. <br/><br/>

            International businesses also comply with laws and regulations regarding shipping to export and import. <br/><br/>

            Pacific Dinamika has the resources and qualifications to offer a custom brokerage service for your custom problem.
        </p>
    } else if (props.num === 6) {
        title = "Warehousing & Distribution"
        desc = <p>
            Warehousing services for cargoes that are not required for immediate use could be stored in our warehouse at a reasonable charge. <br/><br/>

            The office furniture and machinery, household goods, machinery, and spare parts could be stored until required. <br/><br/>

            Generally, we monitor and keep track of all cargoes from the point of loading to their final delivery points.
        </p>
    } else if (props.num === 7) {
        title = "Project Cargoes"
        desc = <p>
            PT. Pacific Dinamika Cargo handles project-specific shipments, including over-size and over-dimensional cargo. <br/><br/>

            We arrange cargo packaging, transport, and clearance from loading ports to unloading ports globally with professional assistance from our foreign partners. <br/><br/>

            We also provide them with cargo track positions, which will significantly help our clients make business decisions.
        </p>
    } else {
        title = "Packing & Removal"
        desc = <p>
            Our well-trained team of professional packers and removals is always on hand to move your personnel, household goods, and personal effects anytime the need arises. We also take good care of your delicate items and highly valued goods. <br/><br/>

            They are handled with all the care they deserve. Appropriate packing materials are used, and forklifts and crates are constructed where required. <br/><br/>

            We can accept the relocations of the office from one place to the other and industrial relocations too. We only require 24 hours prior notice; our packers, who are always on standby, will be ready to attend to your needs.
        </p>
    } 

    return (
        <>
        <div className="our-services-content-img" style={{ backgroundImage: ("url(/images/services-" + props.num + ".jpg)")}}/>
        <div className="our-services-content-container">
            <div className="our-services-content-text">
                <h1 className='our-services-content-text-title'>{title}</h1>
                <div className="our-services-content-text-desc">
                    {desc}
                </div>
            </div>
        </div>
        </>
    );
};

export default ServicePanel;