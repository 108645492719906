import React from 'react';

interface ValueCircleInt{
    image: string
    title: string
    description: string
}
const ValueCircle: React.FC<ValueCircleInt> = (props) => {
    return (
        <div className="home-corporate-value-circle-box-container">
            <div className="home-corporate-value-circle-box-img" style={{ backgroundImage: "url(/images/"+props.image+")" }}/>
            <div className="home-corporate-value-circle-box-title">
                {props.title}
            </div>
            <div className="home-corporate-value-circle-box-desc">
                {props.description}
            </div>
        </div>
    );
};

export default ValueCircle;